<i18n locale="th" lang="yaml" >
chart.empty.default : "ไม่มีข้อมูล"
</i18n>

<template>
	<div :class="cssClasses" :style="cssStyles">
		<div v-if="hasTitle" class="title">
			<slot name="title">
				{{title}}
			</slot>
		</div>

		<div class="mychart-content" :style="contentStyles">
			<slot />

			<a-spin v-if="loading" size="large" class="loading mychart-inner"/>
			<template v-else>
				<div v-if="$notEmpty(errorMessage)" class="error mychart-inner">
					{{errorMessage}}
				</div>
				<div v-else-if="isEmpty" class="empty mychart-inner">
					<a-icon type="line-chart" />
					{{emptyMessage | emptyCheck($t('chart.empty.default'))}}
				</div>
			</template>
		</div>
		<div v-if="showCustomLegend" class="legend-pane">
			<div v-for="legend in legends" :key="legend.label" class="legend-item">
				<div :style="{ backgroundColor: legend.color }" class="legend-color"></div>
				<div class='legend-label'>{{legend.label}}</div>
			</div>
		</div>
		<div v-if="!hideSummary" class="summary">
			<slot v-if="hasSummary" name="summary">
				{{summary}}
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		bordered : {
			type : Boolean ,
			default : false,
		} ,
		title : {
			type : String,
			default : undefined
		} ,
		summary : {
			type : String,
			default : undefined
		} ,
		loading : {
			type : Boolean,
			default : false,
		} ,
		isEmpty : {
			type : Boolean,
			default : true
		} ,
		emptyMessage : {
			type : String,
			default : undefined,
		} ,
		errorMessage : {
			type : String,
			default : undefined,
		} ,
		hideSummary : {
			type : Boolean ,
			default : false
		} ,
 		containerWidth : {
			type : Number,
			default : undefined ,
		} ,
		legends : {
			type : Array ,
			default : () => []
		} ,
		customLegend : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		hasTitle() {
      return !!this.$slots.title || this.$notEmpty(this.title);
		} ,
		hasSummary() {
			return !!this.$slots.summary || this.$notEmpty(this.summary);
		} ,
		cssClasses() {
			return [
				'mychart' ,
				{
					'mychart-border' : this.bordered ,
					'mychart-with-summary' : !this.hideSummary
				}
			]
		} ,
		showCustomLegend() {
			return this.customLegend && this.legends.length > 0
		} ,
		chartWidth() {
			return (this.containerWidth) ? this.containerWidth - 16*2 : undefined;
		} ,
		cssStyles() {
			if (!this.containerWidth)
				return {};
			else
				return {
					maxWidth : this.containerWidth +"px" ,
				}
		} ,
		contentStyles() {
			if (!this.chartWidth)
				return {width : '100%'};
			else
				return {
					width : '100%' ,
					maxWidth : this.chartWidth + "px" ,
				}
		}
	}
}
</script>

<style lang="less" scoped>
.mychart {
	position: relative;
	text-align : center;
	font-size : 0.9em;
	padding : 16px;
	font-family : @font-family-title;
	.title {
		color : @primary-color;
		margin-bottom : 18px;
	}
	.mychart-content {
		position : relative;
		width : 100%;
		.mychart-inner {
			position : absolute;
			top : 50%;
			left : 50%;
			transform: translate(-50%,-50%);
			.anticon  {
				display : block;
				font-size : 4em;
				margin-bottom : 8px;
			}
		}
		.empty {
			color : @text-muted;
		}
		.error {
			color : @error-color;
		}
	}

	&.mychart-border {
		border : 1px solid @border-color-base;
		border-radius : @border-radius-base;

	}
	&.mychart-with-summary {
		padding-bottom : 28px;
	}
	.summary {
		position : absolute;
		bottom : 4px;
		right : 8px;
		text-align : right;
		font-size : 0.95em;
	}
}
.legend-pane {
	display : flex;
	flex-wrap: wrap;
	margin-top : 8px;
}
.legend-item {
	display : flex;
	flex-wrap: nowrap;
	font-size : 0.9em;
	margin-right : 8px;
	.legend-color {
		width : 26px;
		height : 14px;
		margin-right : 2px;
	}
}
</style>
